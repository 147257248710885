import React from 'react';

const HurenCalculator = () => {
    return (
        <>
            <h1>Huurprijs Calculator</h1>
        </>
    );
}

export default HurenCalculator;