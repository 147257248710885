import React from 'react';
import Alert from 'react-bootstrap/Alert';

const BeheerVerwarming = () => {
    return (
        <>
            <h1>Verwarming</h1>
            <Alert variant="danger">
                Toegang tot deze pagina krijg je enkel nadat je bent aangemeld en je beschikt over de nodige accreditaties om de verwarming te beheren.
            </Alert>
        </>
    );
}

export default BeheerVerwarming;
