import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from 'react-router-dom';
// import i18n (needs to be bundled ;))
import './components/i18n/i18n';

const root = createRoot(document.getElementById('root'))
root.render(
  <Auth0Provider
    domain="gymnastiekcentrum-test.eu.auth0.com"
    clientId="gaRCxD5XZp8UDdnSJpHzO3Rn04Vaq71X"
    redirectUri={window.location.origin}
  >
    <React.StrictMode>
      <Router>
        <Suspense fallback="...is loading">
          <App />
        </Suspense>
      </Router>
    </React.StrictMode>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
