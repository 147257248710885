import React from 'react';

const Sportpark = () => {
    return (
        <>
            <h1>Sportpark Heikant</h1>
            <a href="https://kalmthout.be/info/sportaccommodatie-sportpark-heikant/">Officiële pagina van de gemeente Kalmthout</a>
            <br />
            <section id="openingsuren">
                <h2>Openingsuren</h2>
                Het sportpark mag gebruikt worden tussen 8:00 en 22:00.<br />
                <br />
            </section>
            <section id="bereikbaarheid">
                <h2>Bereikbaarheid</h2>
                <iframe title="sportpark-google-maps" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2090.0294638825326!2d4.47950832590471!3d51.3779284153413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1snl!2sbe!4v1616926531777!5m2!1snl!2sbe" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
            </section>
            <section id="verenigingen">
                <h2>Verenigingen</h2>
                <ul>
                    <li><a href="https://www.kalmthoutsk.be/">Voetbalclub K Kalmthout SK</a></li>
                    <li><a href="https://www.ack.be/">Atletiekclub Kalmthout</a></li>
                    <li><a href="https://www.petanqueclub-kalmthout.be/">Petanqueclub Kalmthout</a></li>
                    <li><a href="http://gymka.be">GymKa</a></li>
                    <li><a href="http://www.crescendoturnsport.be/">Crescendo Turnsport Kalmthout</a></li>
                </ul>
            </section>
            <section id="faciliteiten">
                <h2>Faciliteiten</h2>
                <div id="lipsum">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus varius leo risus, sed auctor augue ullamcorper eleifend. Nam dapibus, lorem sed bibendum sagittis, quam eros malesuada dui, a sagittis metus dolor a mi. Sed nec ullamcorper felis. Donec aliquam ipsum ipsum, a interdum enim fringilla vitae. Morbi viverra placerat enim sit amet imperdiet. Vivamus a enim rutrum, dignissim sem eu, aliquet risus. Praesent et ex lorem. Mauris dapibus tempus ante, ac porttitor felis scelerisque at.
</p>
                    <p>
                        Suspendisse dui lorem, efficitur sit amet mauris nec, congue tempus odio. Ut lacinia, ligula in condimentum auctor, ante sapien porttitor urna, a auctor neque nulla vitae leo. Aliquam sit amet pharetra libero. Duis efficitur, erat non sodales ultrices, quam nulla euismod ex, eget facilisis sem libero sit amet felis. Fusce vehicula id ligula a dictum. Vestibulum consequat ante ut nunc pretium, sed vestibulum enim congue. Fusce sodales ipsum et dolor porttitor rutrum.
</p>
                    <p>
                        Phasellus auctor sapien a lacus maximus sagittis. Phasellus et tempus magna. Duis ullamcorper dapibus turpis, ut tincidunt velit. Proin euismod metus non odio volutpat, non gravida tortor auctor. In nec dui dignissim sapien mollis vehicula id eget erat. Maecenas ut nisi ac nisl vehicula molestie eget congue metus. Vestibulum tempus metus sit amet dolor interdum, sed sodales nibh facilisis. Vestibulum leo ante, imperdiet at urna at, mattis tempor lorem. Praesent convallis, odio quis auctor aliquam, nulla tellus rutrum ante, et bibendum felis odio sed eros. Aenean porta at erat at iaculis. Fusce sed lorem convallis, efficitur libero id, blandit purus.
</p>
                    <p>
                        Nam vel dignissim eros. Nulla mattis mauris sodales enim dictum posuere. Nulla facilisi. Nunc porta turpis et aliquam placerat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean orci turpis, suscipit at augue at, auctor viverra tellus. Nulla facilisi. Proin nisi sem, convallis at mauris id, venenatis sollicitudin orci. Quisque nec leo id nulla condimentum ornare vitae sit amet felis. Ut lectus ex, imperdiet nec gravida blandit, vestibulum non orci. Pellentesque tincidunt lectus vitae mauris dictum, sed euismod nisl lobortis. Integer convallis malesuada lorem, non cursus ipsum blandit id. Donec ac elit sit amet nisi euismod interdum. Aliquam erat volutpat.
</p>
                    <p>
                        Nam massa tellus, ultrices vel accumsan ut, posuere sed orci. Quisque ut enim quis justo porttitor fermentum eu non est. Sed dapibus ipsum vitae sodales dignissim. Morbi euismod est eu pretium molestie. Nullam tempor justo ipsum, sit amet congue odio iaculis sed. Proin eu mollis orci. Mauris maximus est eu leo posuere lobortis. Sed mattis porttitor nunc sit amet fringilla. Nunc viverra feugiat augue, at ullamcorper neque semper posuere. Nullam nec imperdiet ex, quis auctor urna. Integer ut turpis odio. Nullam accumsan tortor lectus, ac dignissim nibh tincidunt ut. Nunc consequat orci quis augue tempus dapibus. Sed porta neque eu est venenatis, quis iaculis metus ultrices.
</p></div>            </section>
            <section id="activiteitenkalender">
                <h2>Activiteitenkalender</h2>
                <div id="lipsum">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus varius leo risus, sed auctor augue ullamcorper eleifend. Nam dapibus, lorem sed bibendum sagittis, quam eros malesuada dui, a sagittis metus dolor a mi. Sed nec ullamcorper felis. Donec aliquam ipsum ipsum, a interdum enim fringilla vitae. Morbi viverra placerat enim sit amet imperdiet. Vivamus a enim rutrum, dignissim sem eu, aliquet risus. Praesent et ex lorem. Mauris dapibus tempus ante, ac porttitor felis scelerisque at.
</p>
                    <p>
                        Suspendisse dui lorem, efficitur sit amet mauris nec, congue tempus odio. Ut lacinia, ligula in condimentum auctor, ante sapien porttitor urna, a auctor neque nulla vitae leo. Aliquam sit amet pharetra libero. Duis efficitur, erat non sodales ultrices, quam nulla euismod ex, eget facilisis sem libero sit amet felis. Fusce vehicula id ligula a dictum. Vestibulum consequat ante ut nunc pretium, sed vestibulum enim congue. Fusce sodales ipsum et dolor porttitor rutrum.
</p>
                    <p>
                        Phasellus auctor sapien a lacus maximus sagittis. Phasellus et tempus magna. Duis ullamcorper dapibus turpis, ut tincidunt velit. Proin euismod metus non odio volutpat, non gravida tortor auctor. In nec dui dignissim sapien mollis vehicula id eget erat. Maecenas ut nisi ac nisl vehicula molestie eget congue metus. Vestibulum tempus metus sit amet dolor interdum, sed sodales nibh facilisis. Vestibulum leo ante, imperdiet at urna at, mattis tempor lorem. Praesent convallis, odio quis auctor aliquam, nulla tellus rutrum ante, et bibendum felis odio sed eros. Aenean porta at erat at iaculis. Fusce sed lorem convallis, efficitur libero id, blandit purus.
</p>
                    <p>
                        Nam vel dignissim eros. Nulla mattis mauris sodales enim dictum posuere. Nulla facilisi. Nunc porta turpis et aliquam placerat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean orci turpis, suscipit at augue at, auctor viverra tellus. Nulla facilisi. Proin nisi sem, convallis at mauris id, venenatis sollicitudin orci. Quisque nec leo id nulla condimentum ornare vitae sit amet felis. Ut lectus ex, imperdiet nec gravida blandit, vestibulum non orci. Pellentesque tincidunt lectus vitae mauris dictum, sed euismod nisl lobortis. Integer convallis malesuada lorem, non cursus ipsum blandit id. Donec ac elit sit amet nisi euismod interdum. Aliquam erat volutpat.
</p>
                    <p>
                        Nam massa tellus, ultrices vel accumsan ut, posuere sed orci. Quisque ut enim quis justo porttitor fermentum eu non est. Sed dapibus ipsum vitae sodales dignissim. Morbi euismod est eu pretium molestie. Nullam tempor justo ipsum, sit amet congue odio iaculis sed. Proin eu mollis orci. Mauris maximus est eu leo posuere lobortis. Sed mattis porttitor nunc sit amet fringilla. Nunc viverra feugiat augue, at ullamcorper neque semper posuere. Nullam nec imperdiet ex, quis auctor urna. Integer ut turpis odio. Nullam accumsan tortor lectus, ac dignissim nibh tincidunt ut. Nunc consequat orci quis augue tempus dapibus. Sed porta neque eu est venenatis, quis iaculis metus ultrices.
</p></div>            </section>
        </>
    );
}

export default Sportpark;