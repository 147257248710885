import React from 'react';

const Huren = () => {
    return (
        <>
            <h1>Verhuur</h1>
        </>
    );
}

export default Huren;