import React from 'react';
import Alert from 'react-bootstrap/Alert';

const BeheerVerlichting = () => {
    return (
        <>
            <h1>Verlichting</h1>
            <Alert variant="danger">
                Toegang tot deze pagina krijg je enkel nadat je bent aangemeld en je beschikt over de nodige accreditaties om de verlichting te bedienen.
            </Alert>
        </>
    );
}

export default BeheerVerlichting;
