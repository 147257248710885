import React from 'react';
import { useTranslation } from 'react-i18next';
import img_turnzaal_plan_1 from '../img/Turnzaal_plan_1.jpg';
import img_turnzaal_plan_1_mini from '../img/Turnzaal_plan_1_mini.jpg';

const InfoTurnen = () => {
    const { t } = useTranslation();

    return (
        <>
            <h1>{t('pages_Info-turnen.titel')}</h1>
            <h2>{t('pages_Info-turnen.disciplines')}</h2>
            <div dangerouslySetInnerHTML={{__html: t('pages_Info-turnen.p1', { 'interpolation': {'escapeValue': false} })}} />
            <a href={img_turnzaal_plan_1}><img src={img_turnzaal_plan_1_mini} alt="Plan turnzaal"/></a>
            <div dangerouslySetInnerHTML={{__html: t('pages_Info-turnen.p2', { 'interpolation': {'escapeValue': false} })}} />
            <h2>{t('pages_Info-turnen.afmetingen')}</h2>
            <p>Afmetingen van de turnzaal:
                <ul>
                    <li>Met ingeschoven tribune:
                        <ul>
                            <li>Breedte: 30m</li>
                            <li>Lengte: 45m</li>
                            <li>Hoogte: minimum 6,5m</li>
                            <li>Totale oppervlakte: 1.350m<sup>2</sup></li>
                        </ul>
                    </li>
                    <li>Met uitgeschoven tribune:</li>
                    <ul>
                        <li>Breedte: 28m</li>
                        <li>Lengte: 45m</li>
                        <li>Hoogte: minimum 6,5m</li>
                        <li>Totale oppervlakte: 1.260m<sup>2</sup></li>
                    </ul>
                </ul>
            </p>
            <p></p>
        </>
    );
}

export default InfoTurnen;