import React from 'react';
import Alert from 'react-bootstrap/Alert';

const BeheerElectriciteitsverbruik = () => {
    return (
        <>
            <h1>Electriciteitsverbruik</h1>
            <Alert variant="danger">
                Normaal gezien krijg je enkel toegang tot deze pagina nadat je (1) bent aangemeld en (2) je beschikt over de nodige accreditaties om deze gegevens te bekijken.<br />
                Omwille van demonstratiedoeleinden is deze pagina voorlopig zichtbaar voor iedereen.
            </Alert>
            <h2>Wat en waarom wordt het electriciteitsverbruik gemonitord?</h2>
            <h3>Totaalverbruik - June - Goedkoopste leverancier</h3>
            <p>Enerzijds monitoren we het totale electriciteitsverbruik van het gebouw en vergelijken we constant de
            prijzen van alle electriciteitsleveranciers met elkaar. Als een andere leverancier betere prijzen heeft
                dan schakelen we automatisch over naar die leverancier.</p>
            <p>Dit monitoren, vergelijken en 'switchen' van leverancier doen we niet zelf. Daarvoor maken we
                    dankbaar gebruik van de diensten van <a href="https://www.june.energy/nl/">June Energy</a>, een Belgisch bedrijf
                    dat ook al voor de (gewezen) turnzaal in de Vogelenzangstraat de energiecontracten beheerde.
                </p>
            <p>Een beetje reklame voor June (wij zijn altijd bereikbaar als June ons een sponsorcontract wilt aanbieden):</p>
            <video src="https://www.june.energy/hubfs/June%20-%20Animation.mp4" width="570" controls>
                Sorry, your browser doesn't support embedded videos.
				</video>
            <br />
            <br />
            <h3>Detailverbruik - Eigen soft- en hardware</h3>
            <p>Daar waar June de elektriciteitsmeter in het oog houdt, gaan wij ook nog eens elke elektriciteitskring (meer dan 60 in totaal) apart monitoren.
            Dit stelt ons in staat het energieverbruik in detail te bestuderen en daar waar nodig en mogelijk, energiezuinigere apparaten te installeren, of ze op een beter tijdstip aan te schakelen.
            Doordat we het stroomverbruik in elke kring kennen, kunnen we ook weten waar er bijvoorbeeld nog licht is blijven branden nadat iedereen
                het gebouw reeds heeft verlaten, of bijvoorbeeld dat er een vuur is blijven opstaan in de keuken.</p>
            <p>Analyse van de verbruiksgegevens over een langere periode zal ons ook in staat stellen zeer goed te voorspellen
            wat het verbruik zal zijn bij bepaalde activiteiten (bv verhuur). Deze kennis laat dan weer toe om in de verhuurprijzen rekening te houden met het effectief electriciteitsverbruik.
                </p>
            <p>Voor het monitoren van al deze kringen bouwen we zelf de hard- en softwareoplossing. De hardware bestaat uit volgende componenten:
                <ul>
                    <li>Voor elke kring een hall sensor. Voorbeeld: <a href="https://www.antratek.be/non-invasive-current-sensor-30a?gclid=CjwKCAjw6qqDBhB-EiwACBs6x-mRRzO7RpB1DASnfzvAKP4hLHucIxOdmQrcVm99VQhJUKLnkWr8OxoCdhQQAvD_BwE">SEN-11005</a></li>
                    <li>MegaMUX - 32 Channel Multiplexer. <a href="https://youtu.be/e4h7akFXovM">Informatie over het gebruik.</a></li>
                    <li>Arduino MKR1000 wifi</li>
                </ul>
            </p>
            <p>De Arduino stuurt op regelmatige tijdstippen gemeten stroomwaardes naar de Azure cloud. Die gegevens kunnen nadien gedownload worden om er analyses op los te laten, en kunnen ook via deze website in grafiekvorm bekeken worden.
            Telkens wanneer de cloud een nieuwe set data ontvangt vanuit het gymnastiekcentrum, wordt berekend welke elektrische toestellen aan staan. Deze info wordt
            dan ter beschikking gesteld van de website zodat kan getoond worden welke apparaten actief zijn.
            </p>
        </>
    );
}

export default BeheerElectriciteitsverbruik;