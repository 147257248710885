import './App.css';
import { Route, Routes } from 'react-router-dom';
import MainNav from './components/MainNav/MainNav';
import Home from './pages/Home';
import Info from './pages/Info';
import InfoTurnen from './pages/Info-turnen';
import InfoDansen from './pages/Info-dansen';
import InfoOvernachten from './pages/Info-overnachten';
import InfoSportkampenStages from './pages/Info-sportkampen-stages';
import InfoCursussen from './pages/Info-cursussen';
import Huren from './pages/Huren';
import HurenEenmalig from './pages/Huren-eenmalig';
import HurenSchooljaar from './pages/Huren-schooljaar';
import HurenCalculator from './pages/Huren-calculator';
import Kalender from './pages/Kalender';
import Sportpark from './pages/Sportpark';
import Beheer from './pages/Beheer';
import BeheerAudio from './pages/Beheer-audio';
import BeheerToegang from './pages/Beheer-toegang';
import BeheerVerlichting from './pages/Beheer-verlichting';
import BeheerVerwarming from './pages/Beheer-verwarming';
import BeheerElectriciteitsverbruik from './pages/Beheer-electriciteitsverbruik';

function App() {
  return (
    <div className="App">
      <MainNav />
      <Routes>
        <Route path='/info/turnen' element={<InfoTurnen />} />
        <Route path='/info/dansen' element={<InfoDansen />} />
        <Route path='/info/overnachten' element={<InfoOvernachten />} />
        <Route path='/info/sportkampen-stages' element={<InfoSportkampenStages />} />
        <Route path='/info/cursussen' element={<InfoCursussen />} />
        <Route path='/info' element={<Info />} />
        <Route path='/huren/eenmalig' element={<HurenEenmalig />} />
        <Route path='/huren/schooljaar' element={<HurenSchooljaar />} />
        <Route path='/huren/calculator' element={<HurenCalculator />} />
        <Route path='/huren' element={<Huren />} />
        <Route path='/kalender' element={<Kalender />} />
        <Route path='/sportpark' element={<Sportpark />} />
        <Route path='/beheer/audio' element={<BeheerAudio />} />
        <Route path='/beheer/verlichting' element={<BeheerVerlichting />} />
        <Route path='/beheer/verwarming' element={<BeheerVerwarming />} />
        <Route path='/beheer/toegang' element={<BeheerToegang />} />
        <Route path='/beheer/electriciteit' element={<BeheerElectriciteitsverbruik />} />
        <Route path='/beheer' element={<Beheer />} />
        <Route path='/' element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
