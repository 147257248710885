import React from 'react';

const InfoDansen = () => {
    return (
        <>
            <h1>Info Dansen</h1>
        </>
    );
}

export default InfoDansen;