import React from 'react';

const HurenSchooljaar = () => {
    return (
        <>
            <h1>Huren voor een gans Schooljaar</h1>
        </>
    );
}

export default HurenSchooljaar;