import React from 'react';

const Info = () => {
    return (
        <>
            <h1>Info</h1>
        </>
    );
}

export default Info;