import React from 'react';

const HurenEenmalig = () => {
    return (
        <>
            <h1>Eenmalig Huren</h1>
        </>
    );
}

export default HurenEenmalig;