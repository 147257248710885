import React from 'react';
import Alert from 'react-bootstrap/Alert';

const BeheerToegang = () => {
    return (
        <>
            <h1>Toegang</h1>
            <Alert variant="danger">
                Toegang tot deze pagina krijg je enkel nadat je bent aangemeld en je beschikt over de nodige accreditaties om de toegang tot het gebouw te beheren.
            </Alert>
        </>
    );
}

export default BeheerToegang;
