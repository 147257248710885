import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation, Trans } from 'react-i18next';
//import i18n from '../i18n/i18n';

const lngs = {
    nl: { nativeName: 'Nederlands' },
    en: { nativeName: 'English' }
};

const MainNav = () => {
    // const { isLoading, isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
    const { t, i18n } = useTranslation();

    // let userMenu = <></>;

    // if (isLoading || !isAuthenticated || !user) {
    //     userMenu = <Nav.Link href="#" onClick={() => loginWithRedirect()} disabled>Aanmelden</Nav.Link>
    // }
    // else {
    //     let userDisplayName = user.nickname;
    //     if (!userDisplayName)
    //         userDisplayName = user.firstname;
    //     if (!userDisplayName)
    //         userDisplayName = user.name;

    //     userMenu =
    //         <NavDropdown title={user.name} id="user-dropdown primary">
    //             <NavDropdown.Item href="/user/dashboard">Overzicht</NavDropdown.Item>
    //             <NavDropdown.Item href="/user/profiel">Profiel</NavDropdown.Item>
    //             <NavDropdown.Divider />
    //             <NavDropdown.Item href="#" onClick={() => logout({ returnTo: window.location.origin })}>Afmelden</NavDropdown.Item>
    //         </NavDropdown>;
    // }

    return (
        <Navbar className="bg-primary navbar-dark" expand="lg">
            <Navbar.Brand href="/">
                <Trans i18nKey="Gymhal_Kalmthout">
                    Gymhal Kalmthout
                </Trans>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="main-navbar-nav" />
            <Navbar.Collapse id="main-navbar-nav">
                <Nav>
                    <NavDropdown title="Info" id="info-dropdown" >
                        <NavDropdown.Item href="/info/turnen">{t('info.turnen')}</NavDropdown.Item>
                        <NavDropdown.Item href="/info/dansen">{t('info.dansen')}</NavDropdown.Item>
                        <NavDropdown.Item href="/info/overnachten">{t('info.overnachten')}</NavDropdown.Item>
                        <NavDropdown.Item href="/info/sportkampen-stages">{t('info.sportkampen_stages')}</NavDropdown.Item>
                        <NavDropdown.Item href="/info/cursussen">{t('info.cursussen')}</NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Link href="/kalender" disabled>Kalender</Nav.Link>
                    <NavDropdown title="Sportpark" id="sportpark-dropdown" disabled>
                        <NavDropdown.Item href="/sportpark#openingsuren">Openingsuren</NavDropdown.Item>
                        <NavDropdown.Item href="/sportpark#bereikbaarheid">Bereikbaarheid</NavDropdown.Item>
                        <NavDropdown.Item href="/sportpark#verenigingen">Verenigingen</NavDropdown.Item>
                        <NavDropdown.Item href="/sportpark#faciliteiten">Faciliteiten</NavDropdown.Item>
                        <NavDropdown.Item href="/sportpark#activiteitenkalender">Activiteitenkalender</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Huren" id="huren-dropdown" disabled>
                        <NavDropdown.Item href="/huren/eenmalig">Eenmalige huur</NavDropdown.Item>
                        <NavDropdown.Item href="/huren/schooljaar">Volledig schooljaar huren</NavDropdown.Item>
                        <NavDropdown.Item href="/huren/calculator">Huurprijs calculator</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Gebouwtechnieken" id="beheer-dropdown" disabled>
                        <NavDropdown.Item href="/beheer/audio">Audio</NavDropdown.Item>
                        <NavDropdown.Item href="/beheer/verwarming">Verwarming</NavDropdown.Item>
                        <NavDropdown.Item href="/beheer/verlichting">Verlichting</NavDropdown.Item>
                        <NavDropdown.Item href="/beheer/toegang">Toegangscontrole</NavDropdown.Item>
                        <NavDropdown.Item href="/beheer/electriciteit">Electriciteitsgebruik</NavDropdown.Item>
                    </NavDropdown>
                    {userMenu} */}
                </Nav>
            </Navbar.Collapse>
            <Nav className="me-auto">
                <NavDropdown title={lngs[i18n.resolvedLanguage].nativeName} id="taal-dropdown">
                    {Object.keys(lngs).map((lng) => (
                        <NavDropdown.Item key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                            {lngs[lng].nativeName}
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
            </Nav>
        </Navbar>
    );
}

export default MainNav;