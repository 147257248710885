import React from 'react';
import { useTranslation } from 'react-i18next';
// import HomeCarousel from '../components/HomeCarousel/HomeCarousel';
import img_werfdoek_3 from '../img/Werfdoek_3.jpg';
import img_werfdoek_3_mini from '../img/Werfdoek_3_mini.jpg';
import img_logo_kalmthout from '../img/Kalmthout-logo.jpg';
import img_logo_bladt_verstraeten from '../img/b_en_v-logo.jpg';
import img_logo_vanhout_pro from '../img/Vanhoutpro.jpg';
import img_logo_k_f from '../img/kenf-logo.jpg';
import img_logo_de_kock from '../img/DeKock-logo.jpg';
import img_logo_archimedes from '../img/archimedes-logo.jpg';
import img_logo_boydens from '../img/boydens-logo.jpg';
import img_logo_veilico from '../img/veilico-logo.jpg';
import img_logo_jf from '../img/JF-logo.jpg';
import img_logo_gymka from '../img/gymka-logo.jpg';
import img_logo_crescendo from '../img/crescendo-logo.jpg';
import img_logo_jda from '../img/jda-logo.jpg';

const Home = () => {
    const { t } = useTranslation();

    return (
        <>
            <table>
                <tr>
                    <td colSpan="3">
                        <h1 align="center"><img src={img_logo_kalmthout} width="40" height="40" alt="Logo Kalmthout" />
                            {t('pages_Home.titel')}
                            <img src={img_logo_kalmthout} width="40" height="40" alt="Logo Kalmthout" />
                        </h1>
                    </td>
                </tr>
                <tr>
                    <td colSpan="3">
                        <a href={img_werfdoek_3}><img src={img_werfdoek_3_mini} alt="Werfdoek-3" /></a>
                    </td>
                </tr>
                <tr>
                    <td colSpan="3">
                        <h2>
                            {t('pages_Home.bouwteam.titel')}
                        </h2>
                    </td>
                </tr>
                <tr>
                    <td>{t('pages_Home.bouwteam.bouwheer')}</td>
                    <td><img src={img_logo_kalmthout} height="80" alt="Logo Kalmthout" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.bouwteam.bouwheer_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>{t('pages_Home.bouwteam.architect')}</td>
                    <td><img src={img_logo_bladt_verstraeten} height="80" alt="Logo Kalmthout" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.bouwteam.architect_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>{t('pages_Home.bouwteam.hoofdaannemer')}</td>
                    <td><img src={img_logo_vanhout_pro} height="50" alt="Logo Vanhout.pro" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.bouwteam.hoofdaannemer_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>{t('pages_Home.bouwteam.aannemer_elektriciteit')}</td>
                    <td><img src={img_logo_k_f} height="50" alt="Logo K&F electro-techniek bv" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.bouwteam.aannemer_elektriciteit_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>{t('pages_Home.bouwteam.aannemer_hvac_sanitair')}</td>
                    <td><img src={img_logo_de_kock} height="80" alt="Logo De Kock BV" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.bouwteam.aannemer_hvac_sanitair_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>{t('pages_Home.bouwteam.stabiliteit')}</td>
                    <td><img src={img_logo_archimedes} height="80" alt="Logo Archimedes nv" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.bouwteam.stabiliteit_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>{t('pages_Home.bouwteam.technieken')}</td>
                    <td><img src={img_logo_boydens} height="80" alt="Logo Boydens engineering" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.bouwteam.technieken_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>{t('pages_Home.bouwteam.veiligheidscoordinatie')}</td>
                    <td><img src={img_logo_veilico} height="80" alt="Logo Veilico bv" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.bouwteam.veiligheidscoordinatie_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>{t('pages_Home.bouwteam.turntoestellen')}</td>
                    <td><img src={img_logo_jf} height="37" alt="Logo Janssen Fritsen" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.bouwteam.turntoestellen_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td colSpan="3">
                        <h2>
                            {t('pages_Home.clubs.titel')}
                        </h2>
                    </td>
                </tr>
                <tr>
                    <td>{t('pages_Home.clubs.gymka')}</td>
                    <td><img src={img_logo_gymka} height="80" alt="Logo GymKa" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.clubs.gymka_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>{t('pages_Home.clubs.crescendo')}</td>
                    <td><img src={img_logo_crescendo} height="80" alt="Logo Crescendo" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.clubs.crescendo_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>{t('pages_Home.clubs.jda')}</td>
                    <td><img src={img_logo_jda} height="60" alt="Logo JDA" /></td>
                    <td><div dangerouslySetInnerHTML={{ __html: t('pages_Home.clubs.jda_coordinaten', { 'interpolation': { 'escapeValue': false } }) }} /></td>
                </tr>
            </table>
            {/* <h1>Persvoorstelling plannen Gymhal</h1>
            <p>Vrijdag, 11 maart, 2022</p>
            <br />
            <p><a href="https://photos.app.goo.gl/WwUKX8jyV7bRWTKA9">Album met afbeeldingen 3D SketchUp model (auteur: GymKa)</a>
                <br />
                <a href="https://docs.google.com/presentation/d/1VyOmZoDAcyHpP5BK1EOEfWrMhOwHJ9lG/edit?usp=sharing&ouid=108751450815737167015&rtpof=true&sd=true">PowerPoint GymKa - Crescendo - JDA (auteur: GymKa + Crescendo + JDA)</a>
            </p> */}

        </>
    );
}

export default Home;