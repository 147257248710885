import React from 'react';
import Alert from 'react-bootstrap/Alert';

const Beheer = () => {
    return (
        <>
            <h1>Beheer</h1>
            <Alert variant="danger">
                Toegang tot deze pagina's krijg je enkel nadat je bent aangemeld. Afhankelijk van je accreditatie krijg je dan meer of minder opties aangeboden.
            </Alert>
        </>
    );
}

export default Beheer;
